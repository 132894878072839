import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useShopifyAPI } from '../../hooks';
import { forgotPasswordMutation } from '../../mutations';

export default function ResetPassword() {
  const [forgotPassword, forgotPasswordData, forgotPasswordError, forgotPasswordLoading] = useShopifyAPI();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    email: '',
  });

  //handle change
  const handleChange = (event) => {
    const fieldName = event?.target?.name;
    const fieldValue = event?.target?.value;
    setFormData((prevState) => {
      return {
        ...prevState,
        [fieldName]: fieldValue,
      };
    });
  };

  //handle submit
  const handleSubmit = () => {
    if (formData.email === '') {
      setError({ message: 'Email is required' });
      return;
    }
    console.log(formData, 'formData');
    forgotPassword(forgotPasswordMutation(formData.email), {
      onSuccess: (data) => {
        if (data?.data?.customerRecover?.customerUserErrors?.length) {
          setError({ message: 'User not found' });
          return;
        }
        if (data.errors) {
          console.log(data, 'data.errors');
          setError({ message: data.errors[0].message });
        } else {
          navigate('/reset-success');
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });

    //
  };
  return (
    <div className=" reset-new popup-center-xy popup-access popup-reset-password">
      <div className="wrapper wrapper-xs center-xy">
        <div className="nav-bars">
          <div></div>
          <div></div>
        </div>
        <div className="card-access">
          <h4>Did you forgot your password?</h4>
          <p>Enter your email address you’re using for your account below and we will send a password reset link</p>
          <form className="form-access">
            <div className="form-element email">
              <label>
                Email<span>*</span>
              </label>
              <input type="text" name="email" required value={formData?.email} onChange={handleChange} />
            </div>
            <div className="form-element submit">
              {forgotPasswordLoading ? (
                <div className="btn loader-container btn-md btn-primary-ii btn-submit">
                  <div class="loader-auth"></div>
                </div>
              ) : (
                <input
                  type="button"
                  value="Request Reset Link"
                  className="btn btn-md btn-primary-ii btn-submit"
                  onClick={handleSubmit}
                />
              )}
            </div>
            {error && error?.message && <p style={{ color: 'red' }}>{error.message}</p>}
          </form>
          <div className="register">
            <a href="/login" className="btn-login">
              Back to Log In
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
