export const forgotPasswordMutation = (email) => `mutation customerRecover {
  customerRecover(email: "${email}") {
    customerUserErrors {
      code
      field
      message
    }
  }
}
`;
